import LanguageDropdown from "./LanguageDropdown";
import { Link, useLocation } from "react-router-dom";
import MenuMobile from "./MenuMobile";
import { useCallback, useEffect, useState } from "react";

const Header1 = ({ scroll, isMobileMenu, handleMobileMenu }) => {
  const { pathname } = useLocation();
  const [isActive, setIsActive] = useState("home1");

  useEffect(() => {
    if (pathname) {
      let activePath = pathname.slice(1, pathname.length);
      setIsActive(activePath ? activePath : "home1");
    }
  }, [pathname]);

  const checkActive = (param) => {
    if (param === "pages") {
      switch (isActive) {
        case "pages":
        case "work":
        case "faq":
        case "job-details":
        case "help":
        case "login":
        case "404":
        case "job": {
          return true;
        }
        default:
          return false;
      }
    } else if (param === "home") {
      switch (isActive) {
        case "home1": {
          return true;
        }
        default:
          return false;
      }
    } else {
      switch (isActive) {
        case "news":
        case "blog":
        case "blog-details": {
          return true;
        }
        default:
          return false;
      }
    }
  };

  const handleActive = useCallback(
    (param = "") => {
      setIsActive(param);
    },
    [isActive]
  );

  return (
    <header>
      <div
        id="sticky-header"
        className={`menu-area transparent-header ${
          scroll ? "sticky-menu" : ""
        }`}
      >
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler" onClick={handleMobileMenu}>
                <i className="fas fa-bars" />
              </div>
              <div className="menu-wrap">
                <nav className="menu-nav">
                  <div className="title">
                    <Link to="/">
                      ZADER
                      {/* <img src="/assets/img/logo/logo_update.png" alt="Logo" /> */}
                    </Link>
                  </div>
                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      <li
                        className={`${
                          checkActive("home") ? "active" : ""
                        }`}
                        onClick={() => handleActive("home")}
                      >
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className={`${isActive === "about" ? "active" : ""} `}
                        onClick={() => handleActive("about")}
                      >
                        <Link to="/about">About Us</Link>
                      </li>
                      <li
                        className={`${isActive === "work" ? "active" : ""} `}
                        onClick={() => handleActive("work")}
                      >
                        <Link to="/work">Who We Serve</Link>
                      </li>
                      
                    </ul>
                  </div>
                  <div className="header-action d-none d-md-block">
                    <ul className="list-wrap">
                      {/* <li className="header-lang">
                        <LanguageDropdown />
                      </li> */}
                      <li className="header-btn">
                        <Link to="#" className="btn">
                          COMING SOON
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              {/* Mobile Menu  */}
              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn" onClick={handleMobileMenu}>
                    <i className="fas fa-times" />
                  </div>
                  <div className="nav-logo" onClick={handleMobileMenu}>
                    <Link to="/">
                      <img src="/assets/img/logo/logo.png" alt="Logo" />
                    </Link>
                  </div>
                  <div className="menu-outer">
                    <MenuMobile handleCloseMenu={handleMobileMenu} />
                  </div>
                  <div className="social-links">
                    {/* <ul className="clearfix list-wrap">
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-linkedin-in" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-youtube" />
                        </Link>
                      </li>
                    </ul> */}
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop" onClick={handleMobileMenu} />
              {/* End Mobile Menu */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header1;
