import Layout from "components/layout/Layout";

const Work = () => {
  return (
    <Layout
      headerStyle={1}
      footerStyle={1}
      breadcrumbTitle={
        <>
          {" "}
          Who We <span>Serve</span>
        </>
      }
    >
      <section className="work-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="work-item"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <div className="work-thumb">
                  <img src="assets/img/images/home_buyers.png" alt="" />
                </div>
                <div className="work-content">
                  <span>1</span>
                  <h2 className="title">Home Buyers</h2>
                  <p>
                  Zader’s platform offers a comprehensive real estate service that guides you through scheduling showings and creating offers without a traditional realtor. It provides access to proprietary real estate data, including hundreds of data points of comparison, neighbourhood guides, and unique filters like store delivery and environmental risk scores. The platform’s AI tools offer market analyses and disclosure summaries, and it charges only upon successful home purchase..{" "}
                  </p>
                </div>
              </div>
              <div
                className="work-item"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <div className="work-thumb">
                  <img src="assets/img/images/investors.png" alt="" />
                </div>
                <div className="work-content">
                  <span>2</span>
                  <h2 className="title">
                    Investors
                  </h2>
                  <p>
                  Zader AI adopts machine learning models to analyze market emerging trends, and highlight potential areas for growth areas. The platform detects value creation opportunities,  deals that aren’t on the market hence providing actionable insights and smarter, faster purchases. With a specialized team handling negotiations, paperwork, inspections, and closing, Zader ensures seamless transactions and scalable operations, leveraging advanced technology to elevate property investment for you.
                  </p>
                </div>
              </div>
              <div
                className="work-item"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <div className="work-thumb">
                  <img src="assets/img/images/builders.png" alt="" />
                </div>
                <div className="work-content">
                  <span>3</span>
                  <h2 className="title">
                    Builders
                  </h2>
                  <p>
                  Showcase your available and potential home build portfolio to a pool of qualified buyers at any phase of construction, and connect with the largest centralized network of single-family and multifamily real estate investors and home buyers in the U.K. The platform adopts a data driven matching system with top buyers through  direct-to-buyer platform, ensuring your properties don’t linger on the market. We support you from offer to closing for seamless transactions.
                  </p>
                </div>
              </div>
              <div
                className="work-item"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <div className="work-thumb">
                  <img src="assets/img/images/sellers.png" alt="" />
                </div>
                <div className="work-content">
                  <span>4</span>
                  <h2 className="title">Sellers</h2>
                  <p>
                  Share your listing with Zader to improve the chances of receiving a strong offer, and a faster, smoother closing process. Save more money by avoiding hefty agent fees.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default Work;
