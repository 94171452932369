import React from "react";
import { Link } from "react-router-dom";

function Tools() {
  return (
    <section class="tools-area pb-0">
      {/* <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title text-center mb-80">
              <h2
                class="title title-animation wow fadeInUp"
                data-wow-delay=".2s"
              >
                60+ Powerful <span>Copywriting</span> Tools
              </h2>
            </div>
          </div>
        </div>
        <div class="row justify-content-center row-cols-1 row-cols-xl-5 row-cols-lg-3 row-cols-md-3 row-cols-sm-2">
          <div class="col">
            <div class="tools-item">
              <div class="tools-shape">
                <svg
                  viewBox="0 0 242 142"
                  fill="none"
                  x="0px"
                  y="0px"
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.1">
                    <mask id="tools_1" fill="currentcolor">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.000152349 96C0.000152323 82 7.50015 73 26.223 76.38C43.8227 79.5573 73.5001 62.5 65.0001 34C58.0933 10.8417 68.4855 0.0152226 90.4537 0H212C228.569 0 242 13.4315 242 30V112C242 128.569 228.569 142 212 142H30C13.4315 142 0 128.569 0 112V30C0 29.9678 5.07887e-05 29.9356 0.00015229 29.9034L0.000152349 96Z"
                      />
                    </mask>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.000152349 96C0.000152323 82 7.50015 73 26.223 76.38C43.8227 79.5573 73.5001 62.5 65.0001 34C58.0933 10.8417 68.4855 0.0152226 90.4537 0H212C228.569 0 242 13.4315 242 30V112C242 128.569 228.569 142 212 142H30C13.4315 142 0 128.569 0 112V30C0 29.9678 5.07887e-05 29.9356 0.00015229 29.9034L0.000152349 96Z"
                      fill="currentcolor"
                    />
                    <path
                      d="M26.223 76.38L26.0454 77.3641L26.223 76.38ZM65.0001 34L64.0419 34.2858L65.0001 34ZM90.4537 0V-1L90.453 -1L90.4537 0ZM0.00015229 29.9034H1.00015L-0.999843 29.9002L0.00015229 29.9034ZM26.4007 75.3959C16.885 73.6781 9.97666 75.0628 5.44345 78.9101C0.915774 82.7526 -0.999848 88.8465 -0.999848 96H1.00015C1.00015 89.1535 2.83453 83.7474 6.73758 80.4349C10.6351 77.1272 16.8382 75.7019 26.0454 77.3641L26.4007 75.3959ZM64.0419 34.2858C68.1619 48.1001 63.0535 59.0984 54.7433 66.3139C46.3759 73.5791 34.8547 76.9221 26.4007 75.3959L26.0454 77.3641C35.191 79.0152 47.3083 75.4182 56.0546 67.8241C64.8581 60.1802 70.3384 48.3998 65.9584 33.7142L64.0419 34.2858ZM90.453 -1C79.3518 -0.992307 70.8801 1.74143 66.1178 7.69682C61.3389 13.673 60.5477 22.57 64.0419 34.2858L65.9584 33.7142C62.5458 22.2717 63.4972 14.1764 67.6798 8.94589C71.879 3.69466 79.5874 1.00753 90.4544 1L90.453 -1ZM90.4537 1H212V-1H90.4537V1ZM212 1C228.016 1 241 13.9837 241 30H243C243 12.8792 229.121 -1 212 -1V1ZM241 30V112H243V30H241ZM241 112C241 128.016 228.016 141 212 141V143C229.121 143 243 129.121 243 112H241ZM212 141H30V143H212V141ZM30 141C13.9837 141 1 128.016 1 112H-1C-1 129.121 12.8792 143 30 143V141ZM1 112V30H-1V112H1ZM1 30C1 29.9688 1.00005 29.9377 1.00015 29.9065L-0.999843 29.9002C-0.999948 29.9335 -1 29.9667 -1 30H1ZM1.00015 96V29.9034H-0.999848L-0.999848 96H1.00015Z"
                      fill="currentcolor"
                      mask="url(#tools_1)"
                    />
                  </g>
                </svg>
              </div>
              <div class="tools-icon">
                <i class="far fa-thumbs-up"></i>
              </div>
              <div class="tools-content">
                <h4 class="title">
                  <Link to="login.html">Social media</Link>
                </h4>
                <Link to="login.html" class="link-btn">
                  Select & Try<i class="far fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="tools-item">
              <div class="tools-shape">
                <svg
                  viewBox="0 0 242 142"
                  fill="none"
                  x="0px"
                  y="0px"
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.1">
                    <mask id="tools_2" fill="currentcolor">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.000152349 96C0.000152323 82 7.50015 73 26.223 76.38C43.8227 79.5573 73.5001 62.5 65.0001 34C58.0933 10.8417 68.4855 0.0152226 90.4537 0H212C228.569 0 242 13.4315 242 30V112C242 128.569 228.569 142 212 142H30C13.4315 142 0 128.569 0 112V30C0 29.9678 5.07887e-05 29.9356 0.00015229 29.9034L0.000152349 96Z"
                      />
                    </mask>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.000152349 96C0.000152323 82 7.50015 73 26.223 76.38C43.8227 79.5573 73.5001 62.5 65.0001 34C58.0933 10.8417 68.4855 0.0152226 90.4537 0H212C228.569 0 242 13.4315 242 30V112C242 128.569 228.569 142 212 142H30C13.4315 142 0 128.569 0 112V30C0 29.9678 5.07887e-05 29.9356 0.00015229 29.9034L0.000152349 96Z"
                      fill="currentcolor"
                    />
                    <path
                      d="M26.223 76.38L26.0454 77.3641L26.223 76.38ZM65.0001 34L64.0419 34.2858L65.0001 34ZM90.4537 0V-1L90.453 -1L90.4537 0ZM0.00015229 29.9034H1.00015L-0.999843 29.9002L0.00015229 29.9034ZM26.4007 75.3959C16.885 73.6781 9.97666 75.0628 5.44345 78.9101C0.915774 82.7526 -0.999848 88.8465 -0.999848 96H1.00015C1.00015 89.1535 2.83453 83.7474 6.73758 80.4349C10.6351 77.1272 16.8382 75.7019 26.0454 77.3641L26.4007 75.3959ZM64.0419 34.2858C68.1619 48.1001 63.0535 59.0984 54.7433 66.3139C46.3759 73.5791 34.8547 76.9221 26.4007 75.3959L26.0454 77.3641C35.191 79.0152 47.3083 75.4182 56.0546 67.8241C64.8581 60.1802 70.3384 48.3998 65.9584 33.7142L64.0419 34.2858ZM90.453 -1C79.3518 -0.992307 70.8801 1.74143 66.1178 7.69682C61.3389 13.673 60.5477 22.57 64.0419 34.2858L65.9584 33.7142C62.5458 22.2717 63.4972 14.1764 67.6798 8.94589C71.879 3.69466 79.5874 1.00753 90.4544 1L90.453 -1ZM90.4537 1H212V-1H90.4537V1ZM212 1C228.016 1 241 13.9837 241 30H243C243 12.8792 229.121 -1 212 -1V1ZM241 30V112H243V30H241ZM241 112C241 128.016 228.016 141 212 141V143C229.121 143 243 129.121 243 112H241ZM212 141H30V143H212V141ZM30 141C13.9837 141 1 128.016 1 112H-1C-1 129.121 12.8792 143 30 143V141ZM1 112V30H-1V112H1ZM1 30C1 29.9688 1.00005 29.9377 1.00015 29.9065L-0.999843 29.9002C-0.999948 29.9335 -1 29.9667 -1 30H1ZM1.00015 96V29.9034H-0.999848L-0.999848 96H1.00015Z"
                      fill="currentcolor"
                      mask="url(#tools_2)"
                    />
                  </g>
                </svg>
              </div>
              <div class="tools-icon">
                <i class="fal fa-swatchbook"></i>
              </div>
              <div class="tools-content">
                <h4 class="title">
                  <Link to="login.html">Design</Link>
                </h4>
                <Link to="login.html" class="link-btn">
                  Select & Try<i class="far fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="tools-item">
              <div class="tools-shape">
                <svg
                  viewBox="0 0 242 142"
                  fill="none"
                  x="0px"
                  y="0px"
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.1">
                    <mask id="tools_3" fill="currentcolor">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.000152349 96C0.000152323 82 7.50015 73 26.223 76.38C43.8227 79.5573 73.5001 62.5 65.0001 34C58.0933 10.8417 68.4855 0.0152226 90.4537 0H212C228.569 0 242 13.4315 242 30V112C242 128.569 228.569 142 212 142H30C13.4315 142 0 128.569 0 112V30C0 29.9678 5.07887e-05 29.9356 0.00015229 29.9034L0.000152349 96Z"
                      />
                    </mask>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.000152349 96C0.000152323 82 7.50015 73 26.223 76.38C43.8227 79.5573 73.5001 62.5 65.0001 34C58.0933 10.8417 68.4855 0.0152226 90.4537 0H212C228.569 0 242 13.4315 242 30V112C242 128.569 228.569 142 212 142H30C13.4315 142 0 128.569 0 112V30C0 29.9678 5.07887e-05 29.9356 0.00015229 29.9034L0.000152349 96Z"
                      fill="currentcolor"
                    />
                    <path
                      d="M26.223 76.38L26.0454 77.3641L26.223 76.38ZM65.0001 34L64.0419 34.2858L65.0001 34ZM90.4537 0V-1L90.453 -1L90.4537 0ZM0.00015229 29.9034H1.00015L-0.999843 29.9002L0.00015229 29.9034ZM26.4007 75.3959C16.885 73.6781 9.97666 75.0628 5.44345 78.9101C0.915774 82.7526 -0.999848 88.8465 -0.999848 96H1.00015C1.00015 89.1535 2.83453 83.7474 6.73758 80.4349C10.6351 77.1272 16.8382 75.7019 26.0454 77.3641L26.4007 75.3959ZM64.0419 34.2858C68.1619 48.1001 63.0535 59.0984 54.7433 66.3139C46.3759 73.5791 34.8547 76.9221 26.4007 75.3959L26.0454 77.3641C35.191 79.0152 47.3083 75.4182 56.0546 67.8241C64.8581 60.1802 70.3384 48.3998 65.9584 33.7142L64.0419 34.2858ZM90.453 -1C79.3518 -0.992307 70.8801 1.74143 66.1178 7.69682C61.3389 13.673 60.5477 22.57 64.0419 34.2858L65.9584 33.7142C62.5458 22.2717 63.4972 14.1764 67.6798 8.94589C71.879 3.69466 79.5874 1.00753 90.4544 1L90.453 -1ZM90.4537 1H212V-1H90.4537V1ZM212 1C228.016 1 241 13.9837 241 30H243C243 12.8792 229.121 -1 212 -1V1ZM241 30V112H243V30H241ZM241 112C241 128.016 228.016 141 212 141V143C229.121 143 243 129.121 243 112H241ZM212 141H30V143H212V141ZM30 141C13.9837 141 1 128.016 1 112H-1C-1 129.121 12.8792 143 30 143V141ZM1 112V30H-1V112H1ZM1 30C1 29.9688 1.00005 29.9377 1.00015 29.9065L-0.999843 29.9002C-0.999948 29.9335 -1 29.9667 -1 30H1ZM1.00015 96V29.9034H-0.999848L-0.999848 96H1.00015Z"
                      fill="currentcolor"
                      mask="url(#tools_3)"
                    />
                  </g>
                </svg>
              </div>
              <div class="tools-icon">
                <i class="fal fa-code"></i>
              </div>
              <div class="tools-content">
                <h4 class="title">
                  <Link to="login.html">Coding</Link>
                </h4>
                <Link to="login.html" class="link-btn">
                  Select & Try<i class="far fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="tools-item">
              <div class="tools-shape">
                <svg
                  viewBox="0 0 242 142"
                  fill="none"
                  x="0px"
                  y="0px"
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.1">
                    <mask id="tools_4" fill="currentcolor">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.000152349 96C0.000152323 82 7.50015 73 26.223 76.38C43.8227 79.5573 73.5001 62.5 65.0001 34C58.0933 10.8417 68.4855 0.0152226 90.4537 0H212C228.569 0 242 13.4315 242 30V112C242 128.569 228.569 142 212 142H30C13.4315 142 0 128.569 0 112V30C0 29.9678 5.07887e-05 29.9356 0.00015229 29.9034L0.000152349 96Z"
                      />
                    </mask>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.000152349 96C0.000152323 82 7.50015 73 26.223 76.38C43.8227 79.5573 73.5001 62.5 65.0001 34C58.0933 10.8417 68.4855 0.0152226 90.4537 0H212C228.569 0 242 13.4315 242 30V112C242 128.569 228.569 142 212 142H30C13.4315 142 0 128.569 0 112V30C0 29.9678 5.07887e-05 29.9356 0.00015229 29.9034L0.000152349 96Z"
                      fill="currentcolor"
                    />
                    <path
                      d="M26.223 76.38L26.0454 77.3641L26.223 76.38ZM65.0001 34L64.0419 34.2858L65.0001 34ZM90.4537 0V-1L90.453 -1L90.4537 0ZM0.00015229 29.9034H1.00015L-0.999843 29.9002L0.00015229 29.9034ZM26.4007 75.3959C16.885 73.6781 9.97666 75.0628 5.44345 78.9101C0.915774 82.7526 -0.999848 88.8465 -0.999848 96H1.00015C1.00015 89.1535 2.83453 83.7474 6.73758 80.4349C10.6351 77.1272 16.8382 75.7019 26.0454 77.3641L26.4007 75.3959ZM64.0419 34.2858C68.1619 48.1001 63.0535 59.0984 54.7433 66.3139C46.3759 73.5791 34.8547 76.9221 26.4007 75.3959L26.0454 77.3641C35.191 79.0152 47.3083 75.4182 56.0546 67.8241C64.8581 60.1802 70.3384 48.3998 65.9584 33.7142L64.0419 34.2858ZM90.453 -1C79.3518 -0.992307 70.8801 1.74143 66.1178 7.69682C61.3389 13.673 60.5477 22.57 64.0419 34.2858L65.9584 33.7142C62.5458 22.2717 63.4972 14.1764 67.6798 8.94589C71.879 3.69466 79.5874 1.00753 90.4544 1L90.453 -1ZM90.4537 1H212V-1H90.4537V1ZM212 1C228.016 1 241 13.9837 241 30H243C243 12.8792 229.121 -1 212 -1V1ZM241 30V112H243V30H241ZM241 112C241 128.016 228.016 141 212 141V143C229.121 143 243 129.121 243 112H241ZM212 141H30V143H212V141ZM30 141C13.9837 141 1 128.016 1 112H-1C-1 129.121 12.8792 143 30 143V141ZM1 112V30H-1V112H1ZM1 30C1 29.9688 1.00005 29.9377 1.00015 29.9065L-0.999843 29.9002C-0.999948 29.9335 -1 29.9667 -1 30H1ZM1.00015 96V29.9034H-0.999848L-0.999848 96H1.00015Z"
                      fill="currentcolor"
                      mask="url(#tools_4)"
                    />
                  </g>
                </svg>
              </div>
              <div class="tools-icon">
                <i class="fal fa-bullhorn"></i>
              </div>
              <div class="tools-content">
                <h4 class="title">
                  <Link to="login.html">Marketing</Link>
                </h4>
                <Link to="login.html" class="link-btn">
                  Select & Try<i class="far fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="tools-item">
              <div class="tools-shape">
                <svg
                  viewBox="0 0 242 142"
                  fill="none"
                  x="0px"
                  y="0px"
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.1">
                    <mask id="tools_5" fill="currentcolor">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.000152349 96C0.000152323 82 7.50015 73 26.223 76.38C43.8227 79.5573 73.5001 62.5 65.0001 34C58.0933 10.8417 68.4855 0.0152226 90.4537 0H212C228.569 0 242 13.4315 242 30V112C242 128.569 228.569 142 212 142H30C13.4315 142 0 128.569 0 112V30C0 29.9678 5.07887e-05 29.9356 0.00015229 29.9034L0.000152349 96Z"
                      />
                    </mask>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.000152349 96C0.000152323 82 7.50015 73 26.223 76.38C43.8227 79.5573 73.5001 62.5 65.0001 34C58.0933 10.8417 68.4855 0.0152226 90.4537 0H212C228.569 0 242 13.4315 242 30V112C242 128.569 228.569 142 212 142H30C13.4315 142 0 128.569 0 112V30C0 29.9678 5.07887e-05 29.9356 0.00015229 29.9034L0.000152349 96Z"
                      fill="currentcolor"
                    />
                    <path
                      d="M26.223 76.38L26.0454 77.3641L26.223 76.38ZM65.0001 34L64.0419 34.2858L65.0001 34ZM90.4537 0V-1L90.453 -1L90.4537 0ZM0.00015229 29.9034H1.00015L-0.999843 29.9002L0.00015229 29.9034ZM26.4007 75.3959C16.885 73.6781 9.97666 75.0628 5.44345 78.9101C0.915774 82.7526 -0.999848 88.8465 -0.999848 96H1.00015C1.00015 89.1535 2.83453 83.7474 6.73758 80.4349C10.6351 77.1272 16.8382 75.7019 26.0454 77.3641L26.4007 75.3959ZM64.0419 34.2858C68.1619 48.1001 63.0535 59.0984 54.7433 66.3139C46.3759 73.5791 34.8547 76.9221 26.4007 75.3959L26.0454 77.3641C35.191 79.0152 47.3083 75.4182 56.0546 67.8241C64.8581 60.1802 70.3384 48.3998 65.9584 33.7142L64.0419 34.2858ZM90.453 -1C79.3518 -0.992307 70.8801 1.74143 66.1178 7.69682C61.3389 13.673 60.5477 22.57 64.0419 34.2858L65.9584 33.7142C62.5458 22.2717 63.4972 14.1764 67.6798 8.94589C71.879 3.69466 79.5874 1.00753 90.4544 1L90.453 -1ZM90.4537 1H212V-1H90.4537V1ZM212 1C228.016 1 241 13.9837 241 30H243C243 12.8792 229.121 -1 212 -1V1ZM241 30V112H243V30H241ZM241 112C241 128.016 228.016 141 212 141V143C229.121 143 243 129.121 243 112H241ZM212 141H30V143H212V141ZM30 141C13.9837 141 1 128.016 1 112H-1C-1 129.121 12.8792 143 30 143V141ZM1 112V30H-1V112H1ZM1 30C1 29.9688 1.00005 29.9377 1.00015 29.9065L-0.999843 29.9002C-0.999948 29.9335 -1 29.9667 -1 30H1ZM1.00015 96V29.9034H-0.999848L-0.999848 96H1.00015Z"
                      fill="currentcolor"
                      mask="url(#tools_5)"
                    />
                  </g>
                </svg>
              </div>
              <div class="tools-icon">
                <i class="fal fa-search-plus"></i>
              </div>
              <div class="tools-content">
                <h4 class="title">
                  <Link to="login.html">SEO</Link>
                </h4>
                <Link to="login.html" class="link-btn">
                  Select & Try<i class="far fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div class="more-btn text-center mt-30">
          <Link to="login.html" class="gradient-btn gradient-btn-two btn-two">
            more tools & try for free
          </Link>
        </div>
      </div> */}
    </section>
  );
}

export default Tools;
