import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

function MenuMobile({ handleCloseMenu }) {
  // active menu current
  const { pathname } = useLocation();
  const [current, setCurrent] = useState("home1");

  useEffect(() => {
    if (pathname) {
      let activePath = pathname.slice(1, pathname.length);
      setCurrent(activePath ? activePath : "home1");
    }
  }, [pathname]);

  return (
    <ul className="navigation">
      <li
        className={`${
          current === "home" ? "active" : ""
        } menu-item-has-children tg-mega-menu-has-children`}
        onClick={handleCloseMenu}
      >
        <Link to="/">Home</Link>
      </li>
      <li
        className={`${
          current === "about" ? "active" : ""
        } menu-item-has-children tg-mega-menu-has-children`}
        onClick={handleCloseMenu}
      >
        <Link to="/about">About Us</Link>
      </li>
      <li
        className={`${
          current === "work" ? "active" : ""
        } menu-item-has-children tg-mega-menu-has-children`}
        onClick={handleCloseMenu}
      >
        <Link to="/work">Who We Serve</Link>
      </li>
    </ul>
  );
}

export default MenuMobile;
