import { Link } from 'react-router-dom'
import Typewriter from 'typewriter-effect'

const Banner1 = () => {
    return (
        <section className="banner-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="banner-content ta-animated-headline">
                                <h2 className="title ah-headline wow fadeInUp" data-wow-delay=".2s">
                                    <span>Your AI Real Estate Agent for Smart </span>
                                    <Typewriter tag="span"
                                        options={{
                                            wrapperClassName: "ah-words-wrapper",
                                            strings: ['Purchases', 'Investments', 'Deals'],
                                            autoStart: true,
                                            loop: true,
                                            delay:75,
                                            typeSpeed: 10
                                        }}
                                    />
                                </h2>
                                <h2 className="title d-none wow fadeInUp" data-wow-delay=".2s">Whatever You want to ask- DEX.AI has the <span>Purchases,</span> <span>Investments</span><span>Deals</span></h2>
                                <p className="wow fadeInUp" data-wow-delay=".4s">Our data-driven, cost-effective, self-service platform lets you find and buy residential property without a traditional realtor.</p>
                            </div>
                        </div>
                    </div>
                </div>
        </section>

    )
}

export default Banner1;